/**
* Cards styles
*/
.card {
  border: none;
  border-radius: none;
  box-shadow: var(--clr-card-box-shadow);
  margin-top: 0;
  margin-bottom: 1rem;

  .card-header{
    border: none;
    box-shadow: var(--clr-card-box-shadow);
    font-size: 0.7rem;
  }

  .card-block{
    border: none;
  }

  .card-footer{
    border: none;
  }

  &.no-card {
    border: none;
    background-color: none;
    box-shadow: none;
    border-radius: 0;
  }
}
