:root {
  // flat colors palette
  --flat-turquoise:#1abc9c;
  --flat-greensea: #16a085;
  --flat-sunflower: #f1c40f;
  --flat-orange: #f39c12;
  --flat-emerald:#2ecc71;
  --flat-nephritis:#27ae60;
  --flat-carrot: #e67e22;
  --flat-pumpkin: #d35400;
  --flat-peter-river: #3498db;
  --flat-belize-hole: #2980b9;
  --flat-alizarin: #e74c3c;
  --flat-pomegranate: #c0392b;
  --flat-amethyst: #9b59b6;
  --flat-wisteria: #8e44ad;
  --flat-clouds: #ecf0f1;
  --flat-silver: #bdc3c7;
  --flat-wet-asphalt: #34495e;
  --flat-midmight-blue: #2c3e50;
  --flat-concrete: #95a5a6;
  --flat-asbestos: #7f8c8d;
  --flat-white: #ffffff;
  --clr-header-nav-opacity: 0.95;
  --liksoft-cnss-main-color: #0f458d;
  --liksoft-cnss-yellow-color: #caa618;
  --liksoft-cnss-lighten-yellow-color: #faf5df;
  --liksoft-nav-link-active-bgcolor: #0f458d;
  --clr-header-5-bg-color: var(--liksoft-cnss-main-color);
  --clr-nav-link-active-bgcolor: var(--liksoft-cnss-lighten-yellow-color, #004c8a);
  --clr-nav-link-active-color: var(--liksoft-nav-link-active-bgcolor, #ffffff);

  // Button Spacing
  --clr-btn-vertical-margin: 16px;
  --clr-btn-horizontal-margin: 16px;
  --clr-btn-horizontal-padding: 15px;
  --clr-btn-vertical-padding: 10px;
  --clr-btn-padding: var(--clr-btn-vertical-padding) var(--clr-btn-horizontal-padding);

  // Button Sizing
  --clr-btn-height: 15px;
  --clr-btn-height-sm: 15px;

  // Default font
  // --clr-font: "Open Sans", "Roboto", Arial, sans-serif;
}
