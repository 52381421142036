// Header light

header, .header, .clr-vertical-nav, .main-container{
  background-color: var(--ft-epay-light-over-color);
}


header, .header{
  padding: 0px 1rem;
  .branding {
    .title{
      // color: var(--ft-epay-light-fg-color);
      @include color-blue();
      font-weight: 700;
      margin-right: 1rem;
    }
    .company_name.title{
      color: var(--ft-epay-light-fg-color);
      font-weight: 300;
    }
  }

  .user-state{
    color: var(--ft-epay-light-fg-color);

    .top-hint{
      font-size: 0.6rem;
    }

    .user-name{
      font-weight: 700;
      &.connected cds-icon {
        color: var(--ft-epay-light-status-color);
      }
    }
  }

  .btn.lik-btn{
    background-color: var(--light-global-error-color);
    border-color: var(--light-global-error-color);
  }


}
.content-area{
  background: var(--ft-epay-light-bg-color);
  border-radius: 15px 0 0 0;
  box-shadow: -3px -3px 12px -10px #333;
}

