.main-container .content-container .content-area {
  padding: 1rem;
}
.section.first-section {
  padding: 1rem;
  // border-bottom: 1px solid #ddd;
}

.section-title.align-center {
  font-size: 1.7rem;
  font-weight: 700;
  // color: #333;
}

app-ui-notification {
  position: absolute;
  z-index: 99;
  right: 12px;
  width: 325px;
}

td.user-profile-td{
  width: 100px;
  vertical-align: middle;
}

.profile-picture img {
  width: 100%;
}

.profile-picture {
  text-align: center;
}

table table.table {
  margin: 0;
}

clr-dg-row.datagrid-row{
  &.paid-row, 
  &.success-bg{
    background: var(--ft-epay-light-status-color);
    color: azure;
  }
  &.late-row,
  &.danger-bg{
    background: #ff3456;
    color: rgb(241, 241, 241);
  }
  &.warning-row,
  &.warning-bg{
    background: #f0ab20;
    color: #262525;
  }
  &.success-fg{
    color: var(--ft-epay-light-status-color);
  }
  &.danger-fg{
    color: #ff3456;
  }
  &.warning-fg{
    color: #f0ab20;
  }
}
