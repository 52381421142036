/* You can add global styles to this file, and also import other style files */

@import "assets/mixins/typography";
@import "assets/mixins/colors";
@import "assets/mixins/metrics";
@import "~@ng-select/ng-select/themes/default.theme.css";
// @import "~@ng-select/ng-select/themes/ant.design.theme.css";
// @import "./assets/css/app-dropzone.css";
// @import "./assets/css/content-overlay.css";
// @import "./assets/css/clr-modal.css";
@import "./assets/scss/settings";
@import "./assets/scss/default";
// Documents generation presentation
@import "./assets/mixins/print";
// Document Presentaion
@import "./assets/scss/header";
// @import "./assets/css/document-style";
// Clarity Design Override
@import "./assets/scss/@clr/buttons";
// @import "./assets/scss/@clr/cards";
@import "./assets/scss/@clr/tables";
@import "./assets/scss/@clr/forms";
@import "./assets/scss/@clr/sidenav";
@import "./assets/scss/@clr/datagrid";
@import "./assets/scss/@clr/modal";
@import "./assets/scss/@clr/datagrid";
@import "./assets/scss/login";
// Custom colors
// @import "./assets/css/themes/elewou-blue.css";
// @import "./assets/css/themes/elewou-dark-grey.css";
// @import "./assets/css/themes/elewou-gold.css";
// @import "./assets/css/themes/elewou-indigo.css";
// @import "./assets/css/themes/elewou-green.css";
// @import "./assets/css/themes/elewou-brown.css";
// @import "./assets/css/themes/elewou-red.css";
// @import "./assets/switch/dark";
@import "./assets/switch/light";
@import "./assets/switch/header-light";
