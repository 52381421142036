:root{
  // Colors
  --main-bg-color: #f5f7fb;
  --select-option-bg-color: #ffffff;
  // Main color
  // --clr-primary-color: #3b7ddd;
  // --clr-primary-color: #0072a3;
  --clr-primary-color: #0f458d;

  --clr-primary-hover-color: #326abc;
  --clr-warning-color:rgb(244, 156, 83);
  --clr-warning-hover-color:rgb(231, 106, 3)5;
  --clr-danger-color: #dc3545;
  --clr-danger-hover-color: #bb2d3b;

  // Forms
  --form-group-button-bg: #dfdfdf;
  --field-bg: var(--select-option-bg-color);
  --field-height: 1.4rem;
  --label-font-color: #8e8e8e;
  --clr-inset-box-shadow : 0 0 0.875rem 0 rgba(33, 37, 41, 0.384);
  --field-border-color: #b4b4b4;
  --row-border-color: #bbb;
  --row-even-bg: #e6e6e6;
  --row-odd-bg: #f4f4f4;
  --default-field-font-size: 0.65rem;
  --field-border-radius: 0.20rem;

  // CARDS
  --clr-card-box-shadow : 0 0 0.875rem 0 rgb(33 37 41 / 5%);

}
