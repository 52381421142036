.datagrid-action-bar {
  margin: 0 0 0rem;
}
.datagrid {
  .datagrid-header {
    z-index: 5;
    width: auto;
  }

  .datagrid-row {
    border-top-color: var(--flat-es-swan-white);
  }

  .datagrid-row .datagrid-row-sticky {
    z-index: 5;
  }

  .datagrid-column {
    background: var(--ft-epay-light-over-color);

    .datagrid-column-title {
      color: var(--ft-epay-light-fg-color);
    }
  }
}

clr-dg-detail-header.datagrid-detail-header {
  display: none;
}

.datagrid-detail-body {
  flex: 1 1 auto;
  padding: 0 1.2rem;
  background: #eeeeee;
}
