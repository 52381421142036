.light-mode {
  clr-tabs ul.nav {
    background-color: var(--light-vertical-nav-bg-color);
  }

  h3.section-title {
    background: #f3f3f3;
  }

  .btn.btn-outline-primary,
  .btn-outline-primary .btn {
    border-color: var(--ft-epay-light-fg-color);
    color: var(--ft-epay-light-fg-color);

    &:hover {
      background-color: var(--ft-epay-light-button-bg);
      color: var(--ft-epay-light-over-color);
      border-color: var(--ft-epay-light-button-bg);
    }
  }

  .card {
    border: 1px solid #e6e6e6;
    border-radius: 0;
    display: inline-block;
    margin-top: 0rem;

    &.no-card {
      border: none;
      background-color: none;
      box-shadow: none;
      border-radius: 0;
      margin-top: 0rem;
      margin-bottom: 0rem;
    }

    &.stat-card {
      .card-text {
        font-size: 1rem;
        text-align: left;
        font-weight: 700;
      }
      .card-title {
        font-size: 0.7rem;
        font-weight: 300;
        display: block;
        margin-bottom: 1rem;
      }
    }

    &.alert-card {
      border: 1px solid var(--ft-epay-light-button-bg);
      color: var(--ft-epay-light-button-bg);
    }
    &.success-card {
      border: 1px solid var(--ft-epay-light-status-color);
      color: var(--ft-epay-light-status-color);
    }

    .card-block .progress,
    .card-block .progress-static,
    .card-footer .progress,
    .card-footer .progress-static {
      margin: 0;
      height: 1rem;
      position: relative;
      left: 0;
    }
  }
  .summary-card {
    .card-header {
      background-color: var(--light-vertical-nav-bg-color);
    }
  }
  .modal-header--accessible {
    border-bottom: 1px solid #f3f3f3;
  }

  .ng-select .ng-select-container,
  .clr-input {
    border: 1px solid;
    border-color: var(--light-border-form-el-border-color);
    border-radius: 5px 5px 0 0;
  }

  .clr-input-group {
    border: none;
  }

  .divider {
    margin: 15px 0px;
    border-top: 1px solid #f1f1f1;
  }

  &.login-wrapper {
    background-position: 0;

    .login:after {
      background: transparent;
    }

    .login-overlay {
      background: #0f458de0;
    }

    form.login.clr-form {
      background: transparent;
    }

    form .login-group {
      background: var(--light-vertical-nav-bg-color);
    }

    .login .title .hint {
      color: var(--light-main-font-color);
    }

    .module-name {
      color: var(--light-project-main-color);
    }
  }

  .form-loading {
    height: 200px;
    font-style: italic;
    color: #818181;
    vertical-align: middle;
    display: block;
    background: whitesmoke;
    padding: 3rem 2rem;
    font-size: 1rem;
    width: 100%;
    position: relative;
    margin-top: 1rem;
  }

  .work-in-progress {
    position: relative;
    height: 100%;
    background: #0f458d;
    padding: 2rem 6rem;
    color: #fff;
    font-size: 2rem;
    font-weight: 700;
    .content {
      line-height: 1.2;
      display: table-cell;
      vertical-align: middle;
      height: 600px;
      margin: 0 auto;
    }
  }

  .lik-card-title::after {
    content: "";
    width: 20px;
    height: 20px;
    // border-top: 3px solid var(--ft-epay-light-button-bg);
    // border-right: 3px solid var(--ft-epay-light-button-bg);
    background-color: var(--ft-epay-light-button-bg);
    border-radius: 50%;
    position: absolute;
    left: -35px;
    top: -4px;
  }

  .lik-card-title {
    position: relative;
  }
}
