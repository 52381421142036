.modal {
  z-index: 9;

  .modal-header,
  .modal-header--accessible {
    .modal-title:not([cds-text]) {
      font-weight: 600;
      font-size: 0.8rem;
    }
  }

  .modal-dialog .modal-content {
    padding: 0;
  }

  .modal-body {
    padding: 1rem;
  }

  .modal-dialog {
    .modal-content {
      .modal-header--accessible {
        border: 1px solid #ececec;
        background: lightgrey;
        padding: 0.6rem 0.4rem !important;
      }
    }
  }
}

.modal-body-wrapper {
  overflow: visible;
}
