:root{
  --ft-field-bg: #f6f6f6;
}
.clr-input{
    height: 1.4rem;
    max-height: 1.4rem;
    border: 1px solid #ececec;
    border-radius: 5px;
    &:not([readonly]){
      background-color: var(--ft-field-bg)
    }
}

.clr-form-control{
  .clr-input-wrapper{
    width: 100%;
  }
}

.clr-input-group {
  height: auto;
  border: 1px solid #ececec;
  .clr-input {
    background: 0 0;
    border: 0;
    margin-right: 0;
    max-width: 90%;
    &:not([readonly]){
      background-color: var(--ft-field-bg)
    }
  }
}

.clr-control-container {
  position: relative;

  clr-control-error.field-has-error.clr-subtext {
    position: absolute;
    bottom: -17px;
    left: 0;
    background: #ff494f;
    border-radius: 5px;
    color: white;
    padding: 2px 10px;
    font-size: 10px;
  }
}

.ng-select {

  .ng-select-container {
    background: var(--ft-field-bg);
  }

  .ng-dropdown-panel {
    .ng-dropdown-panel-items {
      .ng-option{
        border-bottom: 1px solid var(--ft-field-bg);
      }
    }
  }
}
