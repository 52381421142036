h2:not([cds-text]) {
  font-weight: 600;
  font-size: 0.9rem;
  color: #535353;
  margin-top: 0;
}


.datagrid,
table{
  // font-family: 'PT Mono', 'Courier New', Courier, monospace;
}
