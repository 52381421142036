.sidenav {
  .sidenav-content{
    &>.nav-link {
      margin: 1.2rem 0 0 0.1rem;
    }
  }
  .nav-group .nav-list {
    padding: 0 0 0 1rem;
  }
}
