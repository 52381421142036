@import "~@ng-select/ng-select/themes/default.theme.css";
:root {
  --main-bg-color: #f5f7fb;
  --select-option-bg-color: #ffffff;
  --clr-primary-color: #0f458d;
  --clr-primary-hover-color: #326abc;
  --clr-warning-color:rgb(244, 156, 83);
  --clr-warning-hover-color:rgb(231, 106, 3)5;
  --clr-danger-color: #dc3545;
  --clr-danger-hover-color: #bb2d3b;
  --form-group-button-bg: #dfdfdf;
  --field-bg: var(--select-option-bg-color);
  --field-height: 1.4rem;
  --label-font-color: #8e8e8e;
  --clr-inset-box-shadow: 0 0 0.875rem 0 rgba(33, 37, 41, 0.384);
  --field-border-color: #b4b4b4;
  --row-border-color: #bbb;
  --row-even-bg: #e6e6e6;
  --row-odd-bg: #f4f4f4;
  --default-field-font-size: 0.65rem;
  --field-border-radius: 0.20rem;
  --clr-card-box-shadow: 0 0 0.875rem 0 rgb(33 37 41 / 5%);
}

/**
* LAYOUT STYLES
* Contains body styles
*/
.header {
  background-color: var(--clr-primary-color);
}

app-ui-notification {
  position: absolute;
  z-index: 99999;
  right: 12px;
  width: 325px;
}

.main-container,
.clr-vertical-nav {
  background-color: var(--main-bg-color);
}

.clr-vertical-nav a.nav-link.active {
  color: var(--clr-primary-color);
  background: rgba(0, 0, 0, 0);
}
.clr-vertical-nav a.nav-link.active .nav-text {
  font-weight: 600;
}
.clr-vertical-nav a.nav-link .nav-text {
  font-weight: 400;
}

.view-header {
  line-height: 1.4;
}
.view-header .view-title {
  font-weight: 700;
  text-transform: uppercase;
  color: var(--clr-primary-color);
}
.view-header .view-description {
  font-size: 0.6rem;
}

.field-has-error.clr-subtext {
  position: absolute;
  top: 0px;
  right: 9px;
  opacity: 0.8;
}
.field-has-error.clr-subtext span {
  font-size: 0.5rem;
}

.intl-tel-input .clr-input {
  display: inline-block;
}

h2:not([cds-text]) {
  font-weight: 600;
  font-size: 0.9rem;
  color: #535353;
  margin-top: 0;
}

.table th {
  vertical-align: middle;
}
.table th.contains-table {
  padding: 0;
  width: 100%;
}
.table th table {
  width: 100%;
}
.table .tb-section-title {
  width: 20%;
  color: #000;
  background-color: transparent;
  border: none;
}
.table.sum-table {
  border: none;
}
.table.sum-table .tb-section-content {
  padding: 0;
}
.table.sum-table .tb-section-content th {
  color: #000;
  background-color: transparent;
  border: none;
  padding: 0;
}
.table.sum-table .tb-section-content td {
  padding: 0;
}
.table.sum-table .tb-section-content td span {
  display: inline-block;
  width: 90%;
  padding: 5px;
  border: 1px solid #444;
  border-radius: 0.4rem;
  background-color: beige;
}
.table.sum-table table {
  width: 100%;
}

.datagrid .reference-cell {
  font-weight: 600;
}

.datagrid-action-overflow .btn {
  display: block;
}

@media print {
  main.content-area {
    width: 100%;
    margin: 0;
    padding: 0;
  }
  html body {
    font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande", "Lucida Sans", Arial, sans-serif;
  }
  @page {
    size: A4 landscape;
    margin: 1cm 1cm 1cm;
    font-family: Arial, Helvetica, sans-serif;
  }
  @page :fisrt {
    margin-top: 2pt;
  }
  .show-for-print {
    display: inline-block;
  }
  .hide-for-print,
.folder-actions {
    display: none;
    width: 0 !important;
    height: 0 !important;
    overflow: hidden !important;
    line-height: 0pt !important;
    white-space: nowrap;
  }
  clr-header.header {
    box-shadow: none !important;
  }
  .header {
    background: transparent;
    height: 20px;
    box-shadow: none;
  }
  .header .header-5 {
    background: transparent;
  }
  .header span.title.app-logo img {
    width: 64px;
  }
  .header .branding {
    height: 20px;
  }
  .header .branding .title {
    line-height: 1.8;
    font-size: 9pt;
    font-weight: 700;
    color: #000;
  }
  .header .branding > .nav-link, .header .branding > a {
    height: 20px;
  }
  .sidenav {
    display: none;
  }
  .main-container {
    background: #ffffff;
  }
  .content-area .app-page-header .card {
    padding: 0;
  }
  .content-area .card {
    border: none;
  }
  .content-area .card .card-block {
    padding: 2pt;
  }
  .content-area .card .card-header {
    padding: 6pt 17pt;
    background: #f3f3f3;
    font-size: 10pt;
  }
  .editor-header {
    position: relative !important;
  }
  .clr-form-control {
    margin: 0;
  }
  .clr-form-control .clr-subtext {
    display: none;
  }
  .clr-control-label {
    font-size: 12px;
    display: inline-block;
    width: 100%;
    color: #ccc;
  }
  app-dynamic-inputs.clr-col-md-6, app-dynamic-inputs.clr-col-4 {
    max-width: 50% !important;
    flex: 0 0 50%;
  }
  .button-container, .btn {
    display: none;
  }
  /* Displaying link color and link behaviour */
  a:link, a:visited, a {
    background: transparent;
    color: rgb(15, 36, 160);
    font-weight: normal;
    text-decoration: none;
    text-align: left;
  }
  a {
    page-break-inside: avoid;
  }
  a[href^=http]:after {
    content: " < " attr(href) "> ";
  }
  a:after > img {
    content: "";
  }
  article a[href^="#"]:after {
    content: "";
  }
  a:not(:local-link):after {
    content: " < " attr(href) "> ";
  }
}
/**
* Cards styles
*/
.card {
  border: none;
  border-radius: none;
  box-shadow: var(--clr-card-box-shadow);
  margin-top: 0;
  margin-bottom: 1rem;
}
.card .card-header {
  border: none;
  box-shadow: var(--clr-card-box-shadow);
  font-size: 0.7rem;
}
.card .card-block {
  border: none;
}
.card .card-footer {
  border: none;
}
.card.no-card {
  border: none;
  background-color: none;
  box-shadow: none;
  border-radius: 0;
}

/* You can add global styles to this file, and also import other style files */
:root {
  --flat-es-jackson-purple: #40407a;
  --flat-es-lucky-point: #2c2c54;
  --flat-es-e64-purple: #706fd3;
  --flat-es-liberty: #474787;
  --flat-es-fluorescent-red: #ff5252;
  --flat-es-eye-of-newt: #b33939;
  --flat-synthetic-pumpkin: #ff793f;
  --flat-chilean-fire: #cd6133;
  --flat-es-swan-white: #f7f1e3;
  --flat-es-hot-stone: #c4c4c4;
  --flat-es-crocodile-tooth: #d1ccc0;
  --flat-es-grey-porcelain: #84817a;
  --flat-es-summer-sky: #34ace0;
  --flat-es-devil-blue: #227093;
  --flat-es-spices-butternut: #ffda79;
  --flat-es-desert: #ccae62;
  --flat-es-mandarin-sorbet: #ffb142;
  --flat-es-alameda-ochre: #cc8e35;
}

:root {
  --light-main-font-color: #333;
  --light-project-main-color: #0f458d;
  --light-project-alt-color: #ffffff;
  --light-alt-font-color: #999;
  --light-main-bg-color: #fff;
  --light-vertical-nav-bg-color: rgb(244, 244, 244);
  --light-vertical-sidebar-icons-color: var(--light-project-main-color);
  --light-header-bg-color: var(--light-project-alt-color);
  --light-global-success-color: #7CB342;
  --light-global-error-color: #FF3D00;
  --light-close-color--normal: #a7a7a7;
  --light-main-btn-color: rgb(255, 255, 255);
  --light-alt-btn-color: rgb(222, 222, 222);
  --light-app-name-color: #fff;
  --light-main-nav-link-color: #4989cd;
  --light-card-bg-color: var(--light-vertical-nav-bg-color);
  --light-border-form-el-border-color: #d2d2d2;
}

:root {
  --dark-main-font-color: rgb(193, 193, 193);
  --dark-project-main-color: #0f458d;
  --dark-project-alt-color: var(--flat-sunflower);
  --dark-alt-font-color: #f5f5f5;
  --dark-main-bg-color: #313131;
  --dark-main-nav-bg-color: #404040;
  --dark-vertical-nav-bg-color: #0E0E0E;
  --dark-vertical-nav-hover-bg-color: #6b6b6b;
  --dark-vertical-sidebar-icons-color: var(--dark-project-main-color);
  --dark-header-bg-color: var(--dark-project-alt-color);
  --dark-global-success-color: #7CB342;
  --dark-global-error-color: #FF3D00;
  --dark-close-color--normal: #757575;
  --dark-app-name-color: #eed171;
  --dark-main-nav-link-color: #e4e4e4;
  --dark-card-bg-color:var(--dark-main-nav-bg-color);
}

:root {
  --ft-epay-light-bg-color: #fff;
  --ft-epay-light-over-color: #f5f5f5;
  --ft-epay-light-status-color: #1e9851;
  --ft-epay-light-fg-color: #333;
  --ft-epay-light-button-bg: #E36100;
  --clr-progress-default-color: var(--ft-epay-light-status-color);
}

:root {
  --clr-global-app-background: var(--light-main-bg-color);
  --clr-global-selection-color:var(--light-project-alt-color);
  --clr-global-on-selection-color: var(--light-project-main-color);
  --clr-global-hover-color: var(--light-project-alt-color);
  --clr-global-content-header-font-color: var(--light-main-font-color);
  --clr-global-font-color: var(--light-main-font-color);
  --clr-global-success-color: var(--light-global-success-color);
  --clr-global-error-color: var(--light-global-error-color);
  --clr-close-color--normal: var(--light-close-color--normal);
  --clr-close-color--normal-opacity: 0.2;
  --clr-close-color--hover: black;
  --clr-close-color--hover-opacity: 0.5;
  --clr-popover-box-shadow-color: rgba(140, 140, 140, 0.25);
  --clr-vertical-nav-bg-color: var(--light-vertical-nav-bg-color);
  --clr-vertical-nav-active-bg-color: var(--light-header-bg-color);
  --clr-vertical-nav-item-color: var(--light-main-font-color);
  --clr-vertical-nav-item-active-color: var(--light-main-bg-color);
  --clr-vertical-nav-icon-active-color: var(--light-project-main-color);
  --clr-vertical-nav-hover-bg-color: var(--light-project-alt-color);
  --clr-vertical-nav-item-active-color: var(--light-project-main-color);
  --clr-header-bg-color: var(--light-project-main-color);
  --clr-h2-color: var(--light-project-main-color);
}

.text-color-blue {
  color: #0f458d;
}

.bg-color-blue {
  background-color: #0f458d;
}

.text-color-yellow {
  color: #ead270;
}

.bg-color-yellow {
  background-color: #ead270;
}

.text-color-orange {
  color: #E36100;
}

.bg-color-orange {
  background-color: #E36100;
}

/** Blue Styles */
/** Yellow Styles */
:root {
  --app-header-height: 3rem;
}

body h1:not([cds-text]) {
  font-size: 1.5rem;
}
body h2:not([cds-text]) {
  font-size: 1.3rem;
}
body h3:not([cds-text]) {
  font-size: 1.1rem;
}
body h4:not([cds-text]) {
  font-size: 1rem;
}
body h5:not([cds-text]) {
  font-size: 0.9rem;
}
body h6:not([cds-text]) {
  font-size: 1.2rem;
}
body p:not([cds-text]) {
  font-size: 0.9rem;
}
body p:not([cds-text]).lead {
  font-size: 1.1rem;
  font-weight: 700;
}
body h1:not([cds-text]),
body h2:not([cds-text]),
body h3:not([cds-text]),
body h4:not([cds-text]),
body h5:not([cds-text]),
body h6:not([cds-text]) {
  margin-bottom: 1rem;
  font-weight: 600;
}

.clr-row .clr-row {
  margin: 0;
}

.alert {
  padding: 0.5rem 1rem;
}

h3.section-title {
  margin-top: 2rem;
  padding: 0.2rem 1rem;
  margin-bottom: 0rem;
  border-radius: 5px 5px 0 0;
}

.retraite-cap-content {
  padding: 2rem 4rem;
}

.app-page-header h3 {
  font-weight: 600;
  font-size: 1rem;
}
.app-page-header h2 {
  font-size: 1rem;
}
.app-page-header .card {
  padding: 0;
  margin-top: 0 !important;
}
.app-page-header p {
  margin-top: 0;
  font-size: small;
}
.app-page-header .button-container {
  margin-top: 0rem;
  margin-bottom: 0.5rem;
}

.editor-header {
  background: #ffe290;
}

.app-intro {
  font-weight: 300;
  font-size: small;
}

.clr-wizard .clr-wizard-stepnav-item {
  box-shadow: none;
}

.clr-wizard-content h2 {
  margin: 0;
}

.clr-vertical-nav .nav-link.active .nav-icon {
  border-radius: 50%;
}

.clr-vertical-nav.has-nav-groups .nav-link, .clr-vertical-nav.has-nav-groups .nav-group .nav-group-text, .clr-vertical-nav.has-nav-groups .nav-group .nav-group-trigger {
  font-weight: 300;
}

.component-container {
  max-width: 80%;
  margin: 1rem auto 0;
}

.card {
  border: none;
}
.card .card-header {
  font-weight: 300;
}
.card .card-block {
  border-bottom: none;
}
.card .text-zone {
  border-right: none;
}
.card.waiting-block, .card.validated-block, .card.rejected-block {
  border-top-width: 5px;
  border-top-style: solid;
}
.card .clr-control-label {
  font-weight: 300;
}
.card.big-card .card-block .big-icon clr-icon {
  height: 32px;
  width: 32px;
  color: #0f468d;
}

h1, h2, h3, h4, h5, h6 {
  text-transform: uppercase;
  font-size: 0.8rem;
}

clr-tabs ul.nav {
  box-shadow: none;
}

.ng-select .ng-select-container, .clr-input {
  border: 1px solid;
  text-transform: initial;
}

.modal-header--accessible {
  padding: 1rem 1.4rem !important;
}

.modal-header--accessible h2.modal-title {
  font-size: 0.8rem;
  font-weight: 300;
}

.ng-value-container {
  line-height: 1;
}

.clr-accordion-panel-open .clr-accordion-header {
  border-width: 1px;
  border-style: solid;
}

/* Change Autocomplete styles in Chrome*/
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border-width: 1px;
  border-style: solid;
  transition: background-color 5000s ease-in-out 0s;
}

.editor-header {
  position: fixed;
  width: 89%;
  margin-left: 12px;
  z-index: 9999;
  height: 2.5rem;
  padding: 0;
}
.editor-header h2 {
  padding: 0 1rem;
}
.editor-header ul.folder-details li:nth-of-type(1) {
  width: 20%;
}
.editor-header ul.folder-details li:nth-of-type(2) {
  width: 20%;
}
.editor-header ul.folder-details li:nth-of-type(3) {
  width: 58%;
}

.lik-card {
  padding: 1rem 3rem;
  margin: 1rem 0 -1rem 0;
  box-sizing: border-box;
}
.lik-card .lik-card-title {
  font-size: 1.8rem;
  font-weight: 600;
  margin: 1rem 0rem;
}
.lik-card .lik-card-description {
  font-size: 0.9rem;
  margin-bottom: 1rem;
}

.lik-datagrid .btn {
  margin: 0;
}

.table .inset-table {
  margin: 0 0 1rem;
}
.table .inset-table tbody th {
  width: 30%;
}
.table .inset-table tbody th, .table .inset-table tbody td {
  border-bottom: 1px solid #eee;
}

:root {
  --flat-turquoise:#1abc9c;
  --flat-greensea: #16a085;
  --flat-sunflower: #f1c40f;
  --flat-orange: #f39c12;
  --flat-emerald:#2ecc71;
  --flat-nephritis:#27ae60;
  --flat-carrot: #e67e22;
  --flat-pumpkin: #d35400;
  --flat-peter-river: #3498db;
  --flat-belize-hole: #2980b9;
  --flat-alizarin: #e74c3c;
  --flat-pomegranate: #c0392b;
  --flat-amethyst: #9b59b6;
  --flat-wisteria: #8e44ad;
  --flat-clouds: #ecf0f1;
  --flat-silver: #bdc3c7;
  --flat-wet-asphalt: #34495e;
  --flat-midmight-blue: #2c3e50;
  --flat-concrete: #95a5a6;
  --flat-asbestos: #7f8c8d;
  --flat-white: #ffffff;
  --clr-header-nav-opacity: 0.95;
  --liksoft-cnss-main-color: #0f458d;
  --liksoft-cnss-yellow-color: #caa618;
  --liksoft-cnss-lighten-yellow-color: #faf5df;
  --liksoft-nav-link-active-bgcolor: #0f458d;
  --clr-header-5-bg-color: var(--liksoft-cnss-main-color);
  --clr-nav-link-active-bgcolor: var(--liksoft-cnss-lighten-yellow-color, #004c8a);
  --clr-nav-link-active-color: var(--liksoft-nav-link-active-bgcolor, #ffffff);
  --clr-btn-vertical-margin: 16px;
  --clr-btn-horizontal-margin: 16px;
  --clr-btn-horizontal-padding: 15px;
  --clr-btn-vertical-padding: 10px;
  --clr-btn-padding: var(--clr-btn-vertical-padding) var(--clr-btn-horizontal-padding);
  --clr-btn-height: 15px;
  --clr-btn-height-sm: 15px;
}

.main-container .content-container .content-area {
  padding: 1rem;
}

.section.first-section {
  padding: 1rem;
}

.section-title.align-center {
  font-size: 1.7rem;
  font-weight: 700;
}

app-ui-notification {
  position: absolute;
  z-index: 99;
  right: 12px;
  width: 325px;
}

td.user-profile-td {
  width: 100px;
  vertical-align: middle;
}

.profile-picture img {
  width: 100%;
}

.profile-picture {
  text-align: center;
}

table table.table {
  margin: 0;
}

clr-dg-row.datagrid-row.paid-row, clr-dg-row.datagrid-row.success-bg {
  background: var(--ft-epay-light-status-color);
  color: azure;
}
clr-dg-row.datagrid-row.late-row, clr-dg-row.datagrid-row.danger-bg {
  background: #ff3456;
  color: rgb(241, 241, 241);
}
clr-dg-row.datagrid-row.warning-row, clr-dg-row.datagrid-row.warning-bg {
  background: #f0ab20;
  color: #262525;
}
clr-dg-row.datagrid-row.success-fg {
  color: var(--ft-epay-light-status-color);
}
clr-dg-row.datagrid-row.danger-fg {
  color: #ff3456;
}
clr-dg-row.datagrid-row.warning-fg {
  color: #f0ab20;
}

/**
 * Print stylesheet
* @version         1.0
* @lastmodified    02.10.2020
* @author          Adevou Fera
*/
@media print {
  main.content-area {
    width: 100%;
    margin: 0;
    padding: 0;
  }
  html body {
    font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande", "Lucida Sans", Arial, sans-serif;
  }
  @page {
    size: A4 landscape;
    margin: 1cm 1cm 1cm;
    font-family: Arial, Helvetica, sans-serif;
  }
  @page :fisrt {
    margin-top: 2pt;
  }
  .show-for-print {
    display: inline-block;
  }
  .hide-for-print,
.folder-actions {
    display: none;
    width: 0 !important;
    height: 0 !important;
    overflow: hidden !important;
    line-height: 0pt !important;
    white-space: nowrap;
  }
  clr-header.header {
    box-shadow: none !important;
  }
  .header {
    background: transparent;
    height: 20px;
    box-shadow: none;
  }
  .header .header-5 {
    background: transparent;
  }
  .header span.title.app-logo img {
    width: 64px;
  }
  .header .branding {
    height: 20px;
  }
  .header .branding .title {
    line-height: 1.8;
    font-size: 9pt;
    font-weight: 700;
    color: #000;
  }
  .header .branding > .nav-link, .header .branding > a {
    height: 20px;
  }
  .sidenav {
    display: none;
  }
  .main-container {
    background: #ffffff;
  }
  .content-area .app-page-header .card {
    padding: 0;
  }
  .content-area .card {
    border: none;
  }
  .content-area .card .card-block {
    padding: 2pt;
  }
  .content-area .card .card-header {
    padding: 6pt 17pt;
    background: #f3f3f3;
    font-size: 10pt;
  }
  .editor-header {
    position: relative !important;
  }
  .clr-form-control {
    margin: 0;
  }
  .clr-form-control .clr-subtext {
    display: none;
  }
  .clr-control-label {
    font-size: 12px;
    display: inline-block;
    width: 100%;
    color: #ccc;
  }
  app-dynamic-inputs.clr-col-md-6, app-dynamic-inputs.clr-col-4 {
    max-width: 50% !important;
    flex: 0 0 50%;
  }
  .button-container, .btn {
    display: none;
  }
  /* Displaying link color and link behaviour */
  a:link, a:visited, a {
    background: transparent;
    color: rgb(15, 36, 160);
    font-weight: normal;
    text-decoration: none;
    text-align: left;
  }
  a {
    page-break-inside: avoid;
  }
  a[href^=http]:after {
    content: " < " attr(href) "> ";
  }
  a:after > img {
    content: "";
  }
  article a[href^="#"]:after {
    content: "";
  }
  a:not(:local-link):after {
    content: " < " attr(href) "> ";
  }
}
.header {
  line-height: 1;
  height: var(--app-header-height);
}
.header .branding {
  height: var(--app-header-height);
}
.header .branding .nav-link {
  height: 1.7rem;
}
.header .branding .title.module_name {
  line-height: var(--app-header-height);
}
.header .branding .app-logo {
  display: block;
  margin-right: 1rem;
  width: 20%;
}
.header .header-nav a.nav-link.nav-text.active,
.header .header-nav a.nav-link.nav-text {
  padding: 13px;
}
.header .header-actions button.nav-icon {
  height: var(--app-header-height);
  width: var(--app-header-height);
}
.header .user-state {
  padding: 7px var(--app-header-height);
}
.header .user-state span {
  display: block;
  line-height: 1.5;
}

header .settings .nav-link.active, header .header-nav .nav-link.active, header .header-actions .nav-link.active, .header .settings .nav-link.active, .header .header-nav .nav-link.active, .header .header-actions .nav-link.active, .header .header-actions .nav-link {
  height: var(--app-header-height);
}

header .settings > .dropdown > .dropdown-toggle, header .header-actions > .dropdown > .dropdown-toggle, .header .settings > .dropdown > .dropdown-toggle, .header .header-actions > .dropdown > .dropdown-toggle {
  line-height: 0rem;
  height: var(--app-header-height);
}

header .header-nav .nav-link:first-of-type::before, header .header-nav .nav-link:last-of-type::after, .header .header-nav .nav-link:first-of-type::before, .header .header-nav .nav-link:last-of-type::after {
  top: 0;
}

header .settings > .dropdown .dropdown-menu, header .header-actions > .dropdown .dropdown-menu, .header .settings > .dropdown .dropdown-menu, .header .header-actions > .dropdown .dropdown-menu {
  margin-top: -1rem;
}

.header .branding .app-logo {
  display: block;
  margin-right: 1rem;
  /* width: 20%; */
  line-height: 1;
  margin-top: 7px;
  font-weight: 700;
}

.dropdown-menu {
  z-index: 99999999999999;
}

.main-container .header-hamburger-trigger {
  height: var(--app-header-height);
}

.sub-navigation {
  font-weight: 300;
  padding: 0.2rem 0.1rem 0;
  position: relative;
  z-index: 9999;
}
:root {
  --clr-btn-vertical-margin: 15px;
  --clr-btn-horizontal-margin: 15px;
  --clr-btn-horizontal-padding: 15px;
  --clr-btn-vertical-padding: 10px;
  --clr-btn-padding: 10px 15px;
  --clr-btn-height: 20px;
  --clr-btn-height-sm: var(--clr-btn-height);
  --clr-btn-font-weight: 300;
  --clr-btn-border-radius: 5px;
  --clr-btn-border-width: 1px;
  --clr-btn-default-color: var(--ft-epay-light-bg-color);
  --clr-btn-default-outline-color: var(--clr-btn-default-color);
  --clr-btn-primary-color: var(--ft-epay-light-bg-color);
  --clr-btn-primary-bg-color: var(--ft-epay-light-button-bg);
  --clr-btn-primary-border-color: var(--clr-btn-primary-bg-color);
  --clr-btn-primary-hover-bg-color: var(--clr-color-action-800);
  --clr-btn-primary-hover-color: var(--clr-color-action-50);
  --clr-btn-primary-box-shadow-color: var(--clr-color-action-500);
  --clr-btn-primary-disabled-color: var(--clr-color-neutral-700);
  --clr-btn-primary-disabled-bg-color: var(--clr-color-neutral-400);
  --clr-btn-primary-disabled-border-color: var(--clr-color-neutral-400);
  --clr-btn-primary-checked-bg-color: var(--clr-btn-default-color);
  --clr-btn-primary-checked-color: var(--light-main-bg-color);
  --clr-btn-success-color: var(--light-main-bg-color);
  --clr-btn-success-bg-color: var(--clr-color-success-700);
  --clr-btn-success-border-color: var(--clr-btn-success-bg-color);
  --clr-btn-success-hover-bg-color: var(--clr-color-success-800);
  --clr-btn-success-hover-color: var(--clr-btn-success-color);
  --clr-btn-success-box-shadow-color: var(--clr-color-success-900);
  --clr-btn-success-disabled-color: var(--clr-color-neutral-700);
  --clr-btn-success-disabled-bg-color: var(--clr-color-neutral-400);
  --clr-btn-success-disabled-border-color: var(--clr-color-neutral-400);
  --clr-btn-success-checked-bg-color: var(--clr-btn-success-hover-bg-color);
  --clr-btn-success-checked-color: var(--clr-btn-success-color);
  --clr-btn-success-outline-color: var(--clr-color-success-700);
  --clr-btn-success-outline-bg-color: var(--clr-btn-outline-bg-color);
  --clr-btn-success-outline-border-color: var(--clr-color-success-700);
  --clr-btn-success-outline-hover-bg-color: var(--clr-color-success-50);
  --clr-btn-success-outline-hover-color: var(--clr-color-success-900);
  --clr-btn-success-outline-box-shadow-color: var(--clr-color-success-400);
  --clr-btn-success-outline-disabled-color: var(--clr-color-neutral-700);
  --clr-btn-success-outline-disabled-bg-color: var(--clr-btn-success-outline-bg-color);
  --clr-btn-success-outline-disabled-border-color: var(--clr-color-neutral-600);
  --clr-btn-success-outline-checked-bg-color: var(--clr-btn-success-outline-border-color);
  --clr-btn-success-outline-checked-color: var(--light-main-bg-color);
  --clr-btn-danger-color: var(--light-main-bg-color);
  --clr-btn-danger-bg-color: var(--clr-color-danger-700);
  --clr-btn-danger-border-color: var(--clr-btn-danger-bg-color);
  --clr-btn-danger-hover-bg-color: var(--clr-color-danger-800);
  --clr-btn-danger-hover-color: var(--clr-btn-danger-color);
  --clr-btn-danger-box-shadow-color: var(--clr-color-danger-900);
  --clr-btn-danger-disabled-color: var(--clr-color-neutral-700);
  --clr-btn-danger-disabled-bg-color: var(--clr-color-neutral-400);
  --clr-btn-danger-disabled-border-color: var(--clr-btn-danger-disabled-color);
  --clr-btn-danger-checked-bg-color: var(--clr-color-danger-800);
  --clr-btn-danger-checked-color: var(--clr-btn-danger-color);
  --clr-btn-danger-outline-color: var(--clr-color-danger-700);
  --clr-btn-danger-outline-bg-color: var(--clr-btn-outline-bg-color);
  --clr-btn-danger-outline-border-color: var(--clr-color-danger-800);
  --clr-btn-danger-outline-hover-bg-color: var(--clr-color-danger-100);
  --clr-btn-danger-outline-hover-color: var(--clr-color-danger-900);
  --clr-btn-danger-outline-box-shadow-color: var(--clr-color-danger-200);
  --clr-btn-danger-outline-disabled-color: var(--clr-color-neutral-700);
  --clr-btn-danger-outline-disabled-bg-color: var(--clr-btn-danger-outline-bg-color);
  --clr-btn-danger-outline-disabled-border-color: var(--clr-btn-danger-outline-disabled-color);
  --clr-btn-danger-outline-checked-bg-color: var(--clr-color-danger-800);
  --clr-btn-danger-outline-checked-color: var(--light-main-bg-color);
  --clr-btn-link-color: var(--clr-btn-default-color);
  --clr-btn-link-bg-color: transparent;
  --clr-btn-link-border-color: transparent;
  --clr-btn-link-hover-bg-color: transparent;
  --clr-btn-link-hover-color: var(--clr-color-action-800);
  --clr-btn-link-disabled-color: var(--clr-color-neutral-700);
  --clr-btn-link-disabled-bg-color: transparent;
  --clr-btn-link-disabled-border-color: transparent;
  --clr-btn-link-checked-bg-color: transparent;
  --clr-btn-link-checked-color: var(--clr-color-action-800);
  --clr-btn-inverse-color: var(--light-main-bg-color);
  --clr-btn-inverse-border-color: var(--light-main-bg-color);
  --clr-btn-inverse-bg-color: transparent;
  --clr-btn-inverse-hover-bg-color: rgba(255, 255, 255, 0.15);
  --clr-btn-inverse-hover-color: var(--light-main-bg-color);
  --clr-btn-inverse-box-shadow-color: rgba(0, 0, 0, 0.25);
  --clr-btn-inverse-disabled-color: var(--light-main-bg-color);
  --clr-btn-inverse-disabled-bg-color: var(--clr-btn-inverse-bg-color);
  --clr-btn-inverse-disabled-border-color: var(--light-main-bg-color);
  --clr-btn-inverse-checked-bg-color: rgba(255, 255, 255, 0.15);
  --clr-btn-inverse-checked-color: var(--light-main-bg-color);
  --clr-card-bg-color:var(--light-main-bg-color) ;
  --clr-card-divider-color: var(--clr-color-neutral-300);
  --clr-card-title-color: var(--clr-h4-color);
  --clr-card-title-font-weight: var(--clr-h4-font-weight);
  --clr-card-border-width: var(--clr-global-borderwidth);
  --clr-card-border-radius: var(--clr-global-borderradius);
  --clr-card-border-color: var(--clr-color-neutral-300);
  --clr-card-box-shadow-color: var(--clr-card-border-color);
  --clr-card-clickable-border-color: var(--clr-color-action-500);
  --clr-card-clickable-box-shadow-color: var(--clr-card-clickable-border-color);
  --clr-badge-success-bg-color: var(--light-global-success-color);
}

.btn {
  border-radius: var(--clr-btn-border-radius);
}
.btn.btn-outline {
  border-width: var(--clr-btn-border-width);
  border-color: var(--ft-epay-light-button-bg);
  color: var(--ft-epay-light-button-bg);
}
.btn.btn-outline clr-icon {
  color: var(--ft-epay-light-button-bg);
}
.btn.btn-link {
  color: var(--ft-epay-light-button-bg);
}
.btn.btn-link clr-icon {
  color: var(--ft-epay-light-button-bg);
}

.table thead th {
  font-size: 14px;
  text-align: left;
}
.table tbody td {
  border-bottom: none;
  border-top-style: none;
}
.table tbody th, .table tbody td {
  font-size: 13px;
  text-align: left;
  padding: 15px 15px;
}

:root {
  --ft-field-bg: #f6f6f6;
}

.clr-input {
  height: 1.4rem;
  max-height: 1.4rem;
  border: 1px solid #ececec;
  border-radius: 5px;
}
.clr-input:not([readonly]) {
  background-color: var(--ft-field-bg);
}

.clr-form-control .clr-input-wrapper {
  width: 100%;
}

.clr-input-group {
  height: auto;
  border: 1px solid #ececec;
}
.clr-input-group .clr-input {
  background: 0 0;
  border: 0;
  margin-right: 0;
  max-width: 90%;
}
.clr-input-group .clr-input:not([readonly]) {
  background-color: var(--ft-field-bg);
}

.clr-control-container {
  position: relative;
}
.clr-control-container clr-control-error.field-has-error.clr-subtext {
  position: absolute;
  bottom: -17px;
  left: 0;
  background: #ff494f;
  border-radius: 5px;
  color: white;
  padding: 2px 10px;
  font-size: 10px;
}

.ng-select .ng-select-container {
  background: var(--ft-field-bg);
}
.ng-select .ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
  border-bottom: 1px solid var(--ft-field-bg);
}

.sidenav .sidenav-content > .nav-link {
  margin: 1.2rem 0 0 0.1rem;
}
.sidenav .nav-group .nav-list {
  padding: 0 0 0 1rem;
}

.datagrid-action-bar {
  margin: 0 0 0rem;
}

.datagrid .datagrid-header {
  z-index: 5;
  width: auto;
}
.datagrid .datagrid-row {
  border-top-color: var(--flat-es-swan-white);
}
.datagrid .datagrid-row .datagrid-row-sticky {
  z-index: 5;
}
.datagrid .datagrid-column {
  background: var(--ft-epay-light-over-color);
}
.datagrid .datagrid-column .datagrid-column-title {
  color: var(--ft-epay-light-fg-color);
}

clr-dg-detail-header.datagrid-detail-header {
  display: none;
}

.datagrid-detail-body {
  flex: 1 1 auto;
  padding: 0 1.2rem;
  background: #eeeeee;
}

.modal {
  z-index: 9;
}
.modal .modal-header .modal-title:not([cds-text]),
.modal .modal-header--accessible .modal-title:not([cds-text]) {
  font-weight: 600;
  font-size: 0.8rem;
}
.modal .modal-dialog .modal-content {
  padding: 0;
}
.modal .modal-body {
  padding: 1rem;
}
.modal .modal-dialog .modal-content .modal-header--accessible {
  border: 1px solid #ececec;
  background: lightgrey;
  padding: 0.6rem 0.4rem !important;
}

.modal-body-wrapper {
  overflow: visible;
}

.datagrid-action-bar {
  margin: 0 0 0rem;
}

.datagrid .datagrid-header {
  z-index: 5;
  width: auto;
}
.datagrid .datagrid-row {
  border-top-color: var(--flat-es-swan-white);
}
.datagrid .datagrid-row .datagrid-row-sticky {
  z-index: 5;
}
.datagrid .datagrid-column {
  background: var(--ft-epay-light-over-color);
}
.datagrid .datagrid-column .datagrid-column-title {
  color: var(--ft-epay-light-fg-color);
}

clr-dg-detail-header.datagrid-detail-header {
  display: none;
}

.datagrid-detail-body {
  flex: 1 1 auto;
  padding: 0 1.2rem;
  background: #eeeeee;
}

.login-wrapper {
  display: flex;
  height: 100%;
  background-size: 100%;
  background-position: 25.2rem 0;
  background-repeat: no-repeat;
  background-image: none;
}
.login-wrapper .login {
  background: transparent;
  border: none;
  margin: 0 auto;
  position: inherit;
}
.login-wrapper .login:after {
  background: transparent;
}
.login-wrapper .login .login-group {
  max-width: 400px;
  background-color: var(--ft-epay-light-bg-color);
  padding: 2.5rem 2rem;
}
.login-wrapper .login .title {
  margin-bottom: 1rem;
}
.login-wrapper .app-login-logo-description {
  padding: 0.5em 0.3em;
  text-align: left;
  font-size: 0.5rem;
  margin-top: 1rem;
}
.login-wrapper .login .login-group .btn {
  margin: 2rem 0 0;
  width: 160px;
  text-align: left;
}
.login-wrapper .login-overlay {
  background: var(--ft-epay-light-over-color);
  position: relative;
  width: 100%;
}
.login-wrapper form.login.clr-form {
  margin: 0 auto;
}
.login-wrapper form .login-group {
  padding: 24px 27px;
  border-radius: 8px;
}
.login-wrapper .login-logo {
  position: relative;
  max-width: 100%;
}
.login-wrapper .login-logo img {
  position: relative;
  width: 41%;
  max-width: 100%;
  margin-bottom: 0.6rem;
}
.login-wrapper .login .title .hint {
  font-size: 12px;
  text-transform: initial;
  margin-bottom: 0;
  margin-top: 0;
  line-height: 1.1;
}
.login-wrapper .module-name {
  font-size: 16px;
  font-weight: 700;
  line-height: 1;
  margin-bottom: 1rem;
}

.light-mode clr-tabs ul.nav {
  background-color: var(--light-vertical-nav-bg-color);
}
.light-mode h3.section-title {
  background: #f3f3f3;
}
.light-mode .btn.btn-outline-primary,
.light-mode .btn-outline-primary .btn {
  border-color: var(--ft-epay-light-fg-color);
  color: var(--ft-epay-light-fg-color);
}
.light-mode .btn.btn-outline-primary:hover,
.light-mode .btn-outline-primary .btn:hover {
  background-color: var(--ft-epay-light-button-bg);
  color: var(--ft-epay-light-over-color);
  border-color: var(--ft-epay-light-button-bg);
}
.light-mode .card {
  border: 1px solid #e6e6e6;
  border-radius: 0;
  display: inline-block;
  margin-top: 0rem;
}
.light-mode .card.no-card {
  border: none;
  background-color: none;
  box-shadow: none;
  border-radius: 0;
  margin-top: 0rem;
  margin-bottom: 0rem;
}
.light-mode .card.stat-card .card-text {
  font-size: 1rem;
  text-align: left;
  font-weight: 700;
}
.light-mode .card.stat-card .card-title {
  font-size: 0.7rem;
  font-weight: 300;
  display: block;
  margin-bottom: 1rem;
}
.light-mode .card.alert-card {
  border: 1px solid var(--ft-epay-light-button-bg);
  color: var(--ft-epay-light-button-bg);
}
.light-mode .card.success-card {
  border: 1px solid var(--ft-epay-light-status-color);
  color: var(--ft-epay-light-status-color);
}
.light-mode .card .card-block .progress,
.light-mode .card .card-block .progress-static,
.light-mode .card .card-footer .progress,
.light-mode .card .card-footer .progress-static {
  margin: 0;
  height: 1rem;
  position: relative;
  left: 0;
}
.light-mode .summary-card .card-header {
  background-color: var(--light-vertical-nav-bg-color);
}
.light-mode .modal-header--accessible {
  border-bottom: 1px solid #f3f3f3;
}
.light-mode .ng-select .ng-select-container,
.light-mode .clr-input {
  border: 1px solid;
  border-color: var(--light-border-form-el-border-color);
  border-radius: 5px 5px 0 0;
}
.light-mode .clr-input-group {
  border: none;
}
.light-mode .divider {
  margin: 15px 0px;
  border-top: 1px solid #f1f1f1;
}
.light-mode.login-wrapper {
  background-position: 0;
}
.light-mode.login-wrapper .login:after {
  background: transparent;
}
.light-mode.login-wrapper .login-overlay {
  background: rgba(15, 69, 141, 0.8784313725);
}
.light-mode.login-wrapper form.login.clr-form {
  background: transparent;
}
.light-mode.login-wrapper form .login-group {
  background: var(--light-vertical-nav-bg-color);
}
.light-mode.login-wrapper .login .title .hint {
  color: var(--light-main-font-color);
}
.light-mode.login-wrapper .module-name {
  color: var(--light-project-main-color);
}
.light-mode .form-loading {
  height: 200px;
  font-style: italic;
  color: #818181;
  vertical-align: middle;
  display: block;
  background: whitesmoke;
  padding: 3rem 2rem;
  font-size: 1rem;
  width: 100%;
  position: relative;
  margin-top: 1rem;
}
.light-mode .work-in-progress {
  position: relative;
  height: 100%;
  background: #0f458d;
  padding: 2rem 6rem;
  color: #fff;
  font-size: 2rem;
  font-weight: 700;
}
.light-mode .work-in-progress .content {
  line-height: 1.2;
  display: table-cell;
  vertical-align: middle;
  height: 600px;
  margin: 0 auto;
}
.light-mode .lik-card-title::after {
  content: "";
  width: 20px;
  height: 20px;
  background-color: var(--ft-epay-light-button-bg);
  border-radius: 50%;
  position: absolute;
  left: -35px;
  top: -4px;
}
.light-mode .lik-card-title {
  position: relative;
}

header, .header, .clr-vertical-nav, .main-container {
  background-color: var(--ft-epay-light-over-color);
}

header, .header {
  padding: 0px 1rem;
}
header .branding .title, .header .branding .title {
  color: #0f458d;
  font-weight: 700;
  margin-right: 1rem;
}
header .branding .company_name.title, .header .branding .company_name.title {
  color: var(--ft-epay-light-fg-color);
  font-weight: 300;
}
header .user-state, .header .user-state {
  color: var(--ft-epay-light-fg-color);
}
header .user-state .top-hint, .header .user-state .top-hint {
  font-size: 0.6rem;
}
header .user-state .user-name, .header .user-state .user-name {
  font-weight: 700;
}
header .user-state .user-name.connected cds-icon, .header .user-state .user-name.connected cds-icon {
  color: var(--ft-epay-light-status-color);
}
header .btn.lik-btn, .header .btn.lik-btn {
  background-color: var(--light-global-error-color);
  border-color: var(--light-global-error-color);
}

.content-area {
  background: var(--ft-epay-light-bg-color);
  border-radius: 15px 0 0 0;
  box-shadow: -3px -3px 12px -10px #333;
}