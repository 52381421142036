/* You can add global styles to this file, and also import other style files */
.fof__animation {
    display: block;
    max-width: 520px;
    margin-left: auto;
    margin-right: auto;
}

.fof {
  margin-top: 10rem;
}

.page-description {
  margin-top: 0rem !important;
  margin-bottom: 1.2rem !important;
}

.mg-1 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}