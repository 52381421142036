// App colors
$colors: (
  "blue":#0f458d,
  "yellow":#ead270,
  "orange" : #E36100
);
// FLAT UI SPANISH PALETTE
:root{
  // purples
  --flat-es-jackson-purple: #40407a;
  --flat-es-lucky-point: #2c2c54;
  --flat-es-e64-purple: #706fd3;
  --flat-es-liberty: #474787;
  // reds
  --flat-es-fluorescent-red: #ff5252;
  --flat-es-eye-of-newt: #b33939;
  // Oranges
  --flat-synthetic-pumpkin: #ff793f;
  --flat-chilean-fire: #cd6133;
  // greys
  --flat-es-swan-white: #f7f1e3;
  --flat-es-hot-stone: #c4c4c4;
  --flat-es-crocodile-tooth: #d1ccc0;
  --flat-es-grey-porcelain: #84817a;
  // blues
  --flat-es-summer-sky: #34ace0;
  --flat-es-devil-blue: #227093;
  // Golds
  --flat-es-spices-butternut: #ffda79;
  --flat-es-desert: #ccae62;
  --flat-es-mandarin-sorbet: #ffb142;
  --flat-es-alameda-ochre: #cc8e35;
}

:root{
  --light-main-font-color: #333;
  --light-project-main-color: #0f458d;
  --light-project-alt-color: #ffffff;
  --light-alt-font-color: #999;
  --light-main-bg-color: #fff;
  --light-vertical-nav-bg-color: rgb(244, 244, 244);
  --light-vertical-sidebar-icons-color: var(--light-project-main-color);
  --light-header-bg-color: var(--light-project-alt-color);
  --light-global-success-color: #7CB342;
  --light-global-error-color: #FF3D00;
  --light-close-color--normal: #a7a7a7;
  --light-main-btn-color: rgb(255, 255, 255);
  --light-alt-btn-color: rgb(222, 222, 222);
  --light-app-name-color: #fff;
  --light-main-nav-link-color: #4989cd;
  --light-card-bg-color: var(--light-vertical-nav-bg-color);
  --light-border-form-el-border-color: #d2d2d2;
}

:root{
  --dark-main-font-color: rgb(193, 193, 193);
  --dark-project-main-color: #0f458d;
  --dark-project-alt-color: var(--flat-sunflower);
  --dark-alt-font-color: #f5f5f5;
  --dark-main-bg-color: #313131;
  --dark-main-nav-bg-color: #404040;
  --dark-vertical-nav-bg-color: #0E0E0E;
  --dark-vertical-nav-hover-bg-color: #6b6b6b;
  --dark-vertical-sidebar-icons-color: var(--dark-project-main-color);
  --dark-header-bg-color: var(--dark-project-alt-color);
  --dark-global-success-color: #7CB342;
  --dark-global-error-color: #FF3D00;
  --dark-close-color--normal: #757575;
  --dark-app-name-color: #eed171;
  --dark-main-nav-link-color: #e4e4e4;
  --dark-card-bg-color:var(--dark-main-nav-bg-color);
}

// E-Pay CSS Variables
:root{
  --ft-epay-light-bg-color: #fff;
  --ft-epay-light-over-color: #f5f5f5;
  --ft-epay-light-status-color: #1e9851;
  --ft-epay-light-fg-color: #333;
  --ft-epay-light-button-bg: #E36100;
  --clr-progress-default-color: var(--ft-epay-light-status-color);
}
:root{
  // Global
  --clr-global-app-background: var(--light-main-bg-color);
  --clr-global-selection-color:var(--light-project-alt-color);
  --clr-global-on-selection-color: var(--light-project-main-color);
  --clr-global-hover-color: var(--light-project-alt-color);
  --clr-global-content-header-font-color: var(--light-main-font-color);
  --clr-global-font-color: var(--light-main-font-color);
  --clr-global-success-color: var(--light-global-success-color);
  --clr-global-error-color: var(--light-global-error-color);
  --clr-close-color--normal: var(--light-close-color--normal);
  --clr-close-color--normal-opacity: 0.2;
  --clr-close-color--hover: black;
  --clr-close-color--hover-opacity: 0.5;
  --clr-popover-box-shadow-color: rgba(140, 140, 140, 0.25);

  // SIDENAV
  --clr-vertical-nav-bg-color: var(--light-vertical-nav-bg-color);
  --clr-vertical-nav-active-bg-color: var(--light-header-bg-color);
  --clr-vertical-nav-item-color: var(--light-main-font-color);
  --clr-vertical-nav-item-active-color: var(--light-main-bg-color);
  --clr-vertical-nav-icon-active-color: var(--light-project-main-color);
  --clr-vertical-nav-hover-bg-color: var(--light-project-alt-color);
  --clr-vertical-nav-item-active-color: var(--light-project-main-color);

  // HEADER
  --clr-header-bg-color: var(--light-project-main-color);

  // TYPOGRAPHY
  --clr-h2-color: var(--light-project-main-color);
}

@each $name, $color in $colors {
  .text-color-#{$name}{
    color: $color;
  }
  .bg-color-#{$name}{
    background-color: $color;
  }
}

/** Blue Styles */
@mixin color-blue{
  color: map-get($colors, "blue");
}

@mixin background-blue{
  background-color: map-get($colors, "blue");
}

@mixin border-blue{
  border-color: map-get($colors, "blue");
}
/** Yellow Styles */
@mixin color-yellow{
  color: map-get($colors, "yellow");
}

@mixin background-yellow{
  background-color: lighten(map-get($colors, "yellow"),25%);
}

@mixin border-yellow{
  border-color: map-get($colors, "yellow");
}
@mixin outline-yellow{
  color: map-get($colors, "yellow");
  background: white;
  border-color: map-get($colors, "yellow");

  &:hover{
    @include background-yellow();
    @include color-blue();
  }
}

@mixin color-blue{
  color: map-get($colors, "blue");
}
