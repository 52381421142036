
.table {
    th{
      vertical-align: middle;

      &.contains-table{
        padding: 0;
        width: 100%;
      }

      table{
        width: 100%;
      }
    }

    .tb-section-title{
        width: 20%;
        color: #000;
        background-color: transparent;
        border: none;
    }

    &.sum-table{
        border: none;
        .tb-section-content{
            padding: 0;

            th{
                color: #000;
                background-color: transparent;
                border: none;
                padding: 0;
            }

            td{
                padding: 0;
                span{
                    display: inline-block;
                    width: 90%;
                    padding: 5px;
                    border: 1px solid #444;
                    border-radius: 0.4rem;
                    background-color: beige;
                }
            }
        }
        table{
            width: 100%;
        }
    }
}

.datagrid{
  .reference-cell{
    font-weight: 600;
  }

}
.datagrid-action-overflow{
  .btn{
    display: block;
  }
}
