.table {
    thead{
        th{
            // background-color: var(--sedana-alt-color);
            font-size: 14px;
            text-align: left;
        }
    }
    tbody{

        td{
            border-bottom: none;
            border-top-style: none;
        }
        th,td {
            font-size: 13px;
            text-align: left;
            // background-color: transparent;
            padding: 15px 15px;
            // border-bottom: 1px solid var(--sedana-alt-color);
        }
    }
}
