:root {
  // Spacing
  --clr-btn-vertical-margin: 15px;
  --clr-btn-horizontal-margin: 15px;
  --clr-btn-horizontal-padding: 15px;
  --clr-btn-vertical-padding: 10px;
  --clr-btn-padding: 10px 15px;

  // Sizing
  --clr-btn-height: 20px;
  --clr-btn-height-sm: var(--clr-btn-height);

  // Fonts
  --clr-btn-font-weight: 300;

  // Borders
  --clr-btn-border-radius: 5px;
  --clr-btn-border-width: 1px;

  // Shared colors
  --clr-btn-default-color: var(--ft-epay-light-bg-color);
  --clr-btn-default-outline-color: var(--clr-btn-default-color);

  // Primary button colors
  --clr-btn-primary-color: var(--ft-epay-light-bg-color);
  --clr-btn-primary-bg-color: var(--ft-epay-light-button-bg);
  --clr-btn-primary-border-color: var(--clr-btn-primary-bg-color);
  --clr-btn-primary-hover-bg-color: var(--clr-color-action-800);
  --clr-btn-primary-hover-color: var(--clr-color-action-50);
  --clr-btn-primary-box-shadow-color: var(--clr-color-action-500);
  --clr-btn-primary-disabled-color: var(--clr-color-neutral-700);
  --clr-btn-primary-disabled-bg-color: var(--clr-color-neutral-400);
  --clr-btn-primary-disabled-border-color: var(--clr-color-neutral-400);
  --clr-btn-primary-checked-bg-color: var(--clr-btn-default-color);
  --clr-btn-primary-checked-color: var(--light-main-bg-color);

  // Success button colors
  --clr-btn-success-color: var(--light-main-bg-color);
  --clr-btn-success-bg-color: var(--clr-color-success-700);
  --clr-btn-success-border-color: var(--clr-btn-success-bg-color);
  --clr-btn-success-hover-bg-color: var(--clr-color-success-800);
  --clr-btn-success-hover-color: var(--clr-btn-success-color);
  --clr-btn-success-box-shadow-color: var(--clr-color-success-900);
  --clr-btn-success-disabled-color: var(--clr-color-neutral-700);
  --clr-btn-success-disabled-bg-color: var(--clr-color-neutral-400);
  --clr-btn-success-disabled-border-color: var(--clr-color-neutral-400);
  --clr-btn-success-checked-bg-color: var(--clr-btn-success-hover-bg-color);
  --clr-btn-success-checked-color: var(--clr-btn-success-color);

  // Success outline button colors
  --clr-btn-success-outline-color: var(--clr-color-success-700);
  --clr-btn-success-outline-bg-color: var(--clr-btn-outline-bg-color);
  --clr-btn-success-outline-border-color: var(--clr-color-success-700);
  --clr-btn-success-outline-hover-bg-color: var(--clr-color-success-50);
  --clr-btn-success-outline-hover-color: var(--clr-color-success-900);
  --clr-btn-success-outline-box-shadow-color: var(--clr-color-success-400);
  --clr-btn-success-outline-disabled-color: var(--clr-color-neutral-700);
  --clr-btn-success-outline-disabled-bg-color: var(--clr-btn-success-outline-bg-color);
  --clr-btn-success-outline-disabled-border-color: var(--clr-color-neutral-600);
  --clr-btn-success-outline-checked-bg-color: var(--clr-btn-success-outline-border-color);
  --clr-btn-success-outline-checked-color: var(--light-main-bg-color);

  // Danger button colors
  --clr-btn-danger-color: var(--light-main-bg-color);
  --clr-btn-danger-bg-color: var(--clr-color-danger-700);
  --clr-btn-danger-border-color: var(--clr-btn-danger-bg-color);
  --clr-btn-danger-hover-bg-color: var(--clr-color-danger-800);
  --clr-btn-danger-hover-color: var(--clr-btn-danger-color);
  --clr-btn-danger-box-shadow-color: var(--clr-color-danger-900);
  --clr-btn-danger-disabled-color: var(--clr-color-neutral-700);
  --clr-btn-danger-disabled-bg-color: var(--clr-color-neutral-400);
  --clr-btn-danger-disabled-border-color: var(--clr-btn-danger-disabled-color);
  --clr-btn-danger-checked-bg-color: var(--clr-color-danger-800);
  --clr-btn-danger-checked-color: var(--clr-btn-danger-color);

  // Danger outline button colors
  --clr-btn-danger-outline-color: var(--clr-color-danger-700);
  --clr-btn-danger-outline-bg-color: var(--clr-btn-outline-bg-color);
  --clr-btn-danger-outline-border-color: var(--clr-color-danger-800);
  --clr-btn-danger-outline-hover-bg-color: var(--clr-color-danger-100);
  --clr-btn-danger-outline-hover-color: var(--clr-color-danger-900);
  --clr-btn-danger-outline-box-shadow-color: var(--clr-color-danger-200);
  --clr-btn-danger-outline-disabled-color: var(--clr-color-neutral-700);
  --clr-btn-danger-outline-disabled-bg-color: var(--clr-btn-danger-outline-bg-color);
  --clr-btn-danger-outline-disabled-border-color: var(--clr-btn-danger-outline-disabled-color);
  --clr-btn-danger-outline-checked-bg-color: var(--clr-color-danger-800);
  --clr-btn-danger-outline-checked-color: var(--light-main-bg-color);

  // Link button colors
  --clr-btn-link-color: var(--clr-btn-default-color);
  --clr-btn-link-bg-color: transparent;
  --clr-btn-link-border-color: transparent;
  --clr-btn-link-hover-bg-color: transparent;
  --clr-btn-link-hover-color: var(--clr-color-action-800);
  --clr-btn-link-disabled-color: var(--clr-color-neutral-700);
  --clr-btn-link-disabled-bg-color: transparent;
  --clr-btn-link-disabled-border-color: transparent;
  --clr-btn-link-checked-bg-color: transparent;
  --clr-btn-link-checked-color: var(--clr-color-action-800);

  // Inverse button colors
  --clr-btn-inverse-color: var(--light-main-bg-color);
  --clr-btn-inverse-border-color: var(--light-main-bg-color);
  --clr-btn-inverse-bg-color: transparent;
  --clr-btn-inverse-hover-bg-color: rgba(255, 255, 255, 0.15);
  --clr-btn-inverse-hover-color: var(--light-main-bg-color);
  --clr-btn-inverse-box-shadow-color: rgba(0, 0, 0, 0.25);
  --clr-btn-inverse-disabled-color: var(--light-main-bg-color);
  --clr-btn-inverse-disabled-bg-color: var(--clr-btn-inverse-bg-color);
  --clr-btn-inverse-disabled-border-color: var(--light-main-bg-color);
  --clr-btn-inverse-checked-bg-color: rgba(255, 255, 255, 0.15);
  --clr-btn-inverse-checked-color: var(--light-main-bg-color);

  // CARDS
  --clr-card-bg-color:var(--light-main-bg-color) ;
  --clr-card-divider-color: var(--clr-color-neutral-300);
  --clr-card-title-color: var(--clr-h4-color);
  --clr-card-title-font-weight: var(--clr-h4-font-weight);
  --clr-card-border-width: var(--clr-global-borderwidth);
  --clr-card-border-radius: var(--clr-global-borderradius);
  --clr-card-border-color: var(--clr-color-neutral-300);
  --clr-card-box-shadow-color: var(--clr-card-border-color);
  --clr-card-clickable-border-color: var(--clr-color-action-500);
  --clr-card-clickable-box-shadow-color: var(--clr-card-clickable-border-color);

  //BADGES
  --clr-badge-success-bg-color: var(--light-global-success-color);
}

.btn{
  border-radius: var(--clr-btn-border-radius);
  &.btn-outline{
    border-width: var(--clr-btn-border-width);
    border-color: var(--ft-epay-light-button-bg);
    color: var(--ft-epay-light-button-bg);
    clr-icon{
      color: var(--ft-epay-light-button-bg);
    }
  }
  &.btn-link{
    color: var(--ft-epay-light-button-bg);
    clr-icon{
      color: var(--ft-epay-light-button-bg);
    }
  }
}
