.login-wrapper{

  display: flex;
  height: 100%;
  background-size: 100%;
  background-position: 25.2rem 0;
  background-repeat: no-repeat;
  background-image: none;

  .login {
    background: transparent;
    border: none;
    margin: 0 auto;
    position: inherit;
    &:after{
      background:transparent;
    }
    .login-group {
      max-width: 400px;
      background-color: var(--ft-epay-light-bg-color);
      padding: 2.5rem 2rem;
    }

    .title{
      margin-bottom: 1rem;
    }
  }

  .app-login-logo-description {
    padding: .5em .3em;
    text-align: left;
    font-size: 0.5rem;
    margin-top: 1rem;
  }

  .login .login-group .btn {
    margin: 2rem 0 0;
    width: 160px;
    text-align: left;
  }

  .login-overlay {
    background: var( --ft-epay-light-over-color);
    position: relative;
    width: 100%;
  }

  form.login.clr-form {
    margin: 0 auto;
  }

  form .login-group {
    padding: 24px 27px;
    border-radius: 8px;
  }

  .login-logo {
    position: relative;
    max-width: 100%;
  }

  .login-logo img {
    position: relative;
    width: 41%;
    max-width: 100%;
    margin-bottom: 0.6rem;
  }


  .login .title .hint {
    font-size: 12px;
    text-transform: initial;
    margin-bottom: 0;
    margin-top: 0;
    line-height: 1.1;
  }

  .module-name {
    font-size: 16px;
    font-weight: 700;
    line-height: 1;
    margin-bottom: 1rem;
  }
}
