/**
* LAYOUT STYLES
* Contains body styles
*/

.header{
  background-color: var(--clr-primary-color);
}


app-ui-notification {
  position: absolute;
  z-index: 99999;
  right: 12px;
  width: 325px;
}

.main-container,
.clr-vertical-nav{
  background-color: var(--main-bg-color);
}

.clr-vertical-nav{
  a.nav-link{
    &.active {
      color: var(--clr-primary-color);
      background: #0000;
      .nav-text{
        font-weight: 600;
      }
    }

    .nav-text{
      font-weight: 400;
    }
  }
}

.view-header{
  line-height: 1.4;

  .view-title {
    font-weight: 700;
    text-transform: uppercase ;
    color: var(--clr-primary-color);
  }

  .view-description {
    font-size: 0.6rem
  }
}


// Styling error messages
.field-has-error.clr-subtext {
  position: absolute;
  top: 0px;
  right: 9px;
  opacity: 0.8;

  span {
    font-size: 0.5rem;
  }
}


.intl-tel-input .clr-input {
  display: inline-block;
}
